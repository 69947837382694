<template>
  <div>
    <template v-if="gbId===null">
      <Row class="p-b-5">
        <i-col span="24" class="text-center">
          <ButtonGroup>
              <Button :class="showType===1?'':'btn-black'" :type="showType===1?'primary':'default'" style="width:160px" @click="handleChangeShowType(1)">资源预约成交率</Button>
              <Button :class="showType===2?'':'btn-black'" :type="showType===2?'primary':'default'" style="width:160px" @click="handleChangeShowType(2)">资源预售率</Button>
          </ButtonGroup>
        </i-col>
      </Row>

      <Row class="p-l-10 p-t-10" v-for="(item, index) in currentArray" :key="index">
        <i-col span="16">{{item.name?item.name:item.stationName}}</i-col>
        <i-col span="8">{{item.rate}} %</i-col>
      </Row>
      <p v-if="showArray.length===0" class="text-center remark p-t-20">暂无数据</p>
      <div v-show="showArray.length>currentPageSize" class="paging_style">
          <Page size="small" :total="showArray.length" :page-size="currentPageSize" :current="currentPageNumber" @on-change="handlePageChange"></Page>
      </div>
    </template>

    <template v-else>
      <h2 class="text-center p-b-10">站点资源售卖排名</h2>
      <Row  class="table-title" :gutter="8">
        <i-col span="6">资源类型</i-col>
        <i-col span="6">库存</i-col>
        <i-col span="6">售卖</i-col>
        <i-col span="6">去化率</i-col>
      </Row>
      <Row v-for="(item,index) in resourceUsedGbData" :key="index" :gutter="8" class="p-t-10">
        <i-col span="6">{{item.name}}</i-col>
        <i-col span="6">{{formatNumber(item.targetAmount)}}</i-col>
        <i-col span="6">{{formatNumber(item.actualAmount)}}</i-col>
        <i-col span="6">{{item.rate}}%</i-col>
      </Row>

      <!-- <Table size="small" :columns="resourceUsedGbColumn" :data="resourceUsedGbData"></Table> -->
    </template>
  </div>
</template>

<script>
import { toNumber } from '@/utils/wnumb_own'
import { listResourceRateGbStation, listBookRateGbStation } from '@/api/dw/station'
import { listResourceUsedGb } from '@/api/dw/order'

export default {
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,
      showType: 1, // 显示类型，1.预约成交率 2.预售率
      resourceRateArray: [],
      bookRateArray: [],

      showArray: [],
      currentArray: [],
      currentPageSize: 25,
      currentPageNumber: 1,

      resourceUsedGbData: []
    }
  },
  methods: {
    initPageData () {
      this.showArray = []
      this.currentArray = []

      const queryModel = {
        endDate: this.searchSchedule.endDate,
        publisherId: this.publisherId,
        startDate: this.searchSchedule.startDate,
        assetId: this.assetId,
        companyId: this.companyId
      }
      // 预售率
      listResourceRateGbStation(queryModel).then(res => {
        this.resourceRateArray = res
        this.$store.commit('set_situation_map_stations', res)
        this.$store.commit('set_situation_beignUpdateMap', new Date())
      })
      // 预约成交率
      listBookRateGbStation(queryModel).then(res => {
        this.bookRateArray = res
        this.showArray = res
        this.handleChangeShowType(1)
      })
    },
    handleChangeShowType (val) {
      this.showType = val
      this.showArray = val === 1 ? this.bookRateArray : this.resourceRateArray
      this.handlePageChange(1)
    },
    handlePageChange (page) {
      this.currentPageNumber = page
      const newArray = []
      for (let index = this.currentPageSize * (page - 1); index < this.currentPageSize * page; index++) {
        if (index < this.showArray.length) {
          newArray.push(this.showArray[index])
        } else {
          break
        }
      }

      this.currentArray = newArray
    },
    loadResourceUseGb () { // 用于左侧点击站点后， 显示资源售卖排名
      const queryModel = {
        endDate: this.searchSchedule.endDate,
        publisherId: this.publisherId,
        startDate: this.searchSchedule.startDate,
        assetId: this.assetId,
        companyId: this.companyId,

        gbType: 'resourceType', // month:月份;resourceType:资源类型;station:站点
        stationId: this.gbId
      }

      listResourceUsedGb(queryModel).then(res => {
        this.resourceUsedGbData = res
      })
    },
    formatNumber (number) {
      return toNumber(number)
    }
  },
  computed: {
    searchSchedule () {
      return this.$store.state.situationWeb.searchSchedule
    },
    assetId () {
      return this.$store.state.situationWeb.assetId
    },
    companyId () {
      return this.$store.state.situationWeb.companyId
    },
    beignUpdateBottomAndRight () {
      return this.$store.state.situationWeb.beignUpdateBottomAndRight
    },
    gbId () {
      return this.$store.state.situationWeb.gbId
    }
  },
  watch: {
    beignUpdateBottomAndRight () {
      if (this.gbId) {
        this.loadResourceUseGb()
      } else {
        this.initPageData()
      }
    }
  }
}
</script>
